import {Component, Input, ChangeDetectionStrategy, forwardRef} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'app-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuantitySelectorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => QuantitySelectorComponent),
      multi: true,
    },
  ],
})
export class QuantitySelectorComponent implements ControlValueAccessor, Validator {
  @Input() minValue = 0;
  @Input() maxValue?: number;
  @Input() minStep = 1;

  protected quantity = this.fb.control(0, [this.validate.bind(this)]);

  private onChange: (value: number) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private fb: FormBuilder) {
    this.quantity.valueChanges.subscribe((v) => {
      const value = parseInt(v as unknown as string) || 0;
      this.onChange(value);
    });
  }

  increment(event: Event) {
    event.stopPropagation();
    let value = parseInt(this.quantity.value as unknown as string) || 0;
    if (value == this.maxValue) {
      return;
    }
    value += this.minStep;
    if (value < this.minValue) {
      value = this.minValue;
    }
    if (this.maxValue && value > this.maxValue) {
      value = this.maxValue;
    }
    this.quantity.patchValue(value);
    this.onChange(value);
  }

  decrement(event: Event) {
    event.stopPropagation();
    let value = parseInt(this.quantity.value as unknown as string) || 0;
    if (value == this.minValue) {
      return;
    }
    value -= this.minStep;
    if (value < this.minValue) {
      value = this.minValue;
    }
    if (this.maxValue && value > this.maxValue) {
      value = this.maxValue;
    }
    this.quantity.patchValue(value);
    this.onChange(value);
  }

  writeValue(value: number): void {
    this.quantity.patchValue(value);
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value < this.minValue) {
      return {minValue: this.minValue};
    }

    if (this.maxValue && value > this.maxValue) {
      return {maxValue: this.maxValue};
    }

    return null;
  }
}

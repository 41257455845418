<div *ngIf="displayData().nickname" class="avatar-container item-container">
  <img type="avatar" [src]="displayData().avatar_url" width="40" height="40" />
  <div>{{ displayData().nickname }}</div>
</div>
<ng-container *ngIf="displayData().signal">
  <div class="item-container icon-container" *ngIf="!isSignalLink(displayData().signal)">
    <mat-icon [copyToClipboard]="displayData().signal" class="signal-container" svgIcon="signal"></mat-icon>
  </div>

  <a [href]="displayData().signal" target="_blank" *ngIf="isSignalLink(displayData().signal)">
    <div class="item-container icon-container">
      <mat-icon class="signal-container" svgIcon="signal"></mat-icon>
    </div>
  </a>
</ng-container>

<a [href]="'https://t.me/' + displayData().telegram" target="_blank" *ngIf="displayData().telegram">
  <div class="item-container icon-container">
    <mat-icon svgIcon="telegram"></mat-icon>
  </div>
</a>

<div [copyToClipboard]="displayData().whatsapp" *ngIf="displayData().whatsapp" class="item-container icon-container">
  <mat-icon class="whatsapp-container" svgIcon="whatsapp"></mat-icon>
</div>

import {CommonModule} from '@angular/common';
import {NgModule, PipeTransform, Type} from '@angular/core';
import {FormatDataTypePipe, KgWeightPipe, FileSizePipe, ProductImgPipe, SortByDatePipe} from '.';
import {FormatNumberPipe} from './format-number.pipe';
import {SafeHtmlPipe} from '@app/shared/pipes/safe-html.pipe';

const SHARED_PIPES: Type<PipeTransform>[] = [
  ProductImgPipe,
  FileSizePipe,
  KgWeightPipe,
  FormatDataTypePipe,
  FormatNumberPipe,
  SortByDatePipe,
  SafeHtmlPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...SHARED_PIPES],
  exports: [...SHARED_PIPES],
})
export class SharedPipesModule {}

import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {TranslateService} from '@ngx-translate/core';
import {SnackBarService} from '@services/snack-bar.service';

@Directive({
  selector: '[copyToClipboard]',
})
export class CopyToClipboardDirective implements OnInit {
  @Input('copyToClipboard')
  public payload!: string;

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.clipboard.copy(this.payload);

    this.snackBarService.success(this.i18n.instant('copied'));
  }

  constructor(
    private clipboard: Clipboard,
    private i18n: TranslateService,
    private snackBarService: SnackBarService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'copy');
  }
}

import {RouteItem} from './../../../core/enums/route.enum';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
  protected readonly mimeTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
  @Input() status: string = '';
  @Input() paymentSource?: string;
  @Output() upload = new EventEmitter<FileList>();

  RouteItem = RouteItem;

  onUpload(event: Event) {
    event.stopPropagation();
  }

  onFileChange(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      this.upload.emit(files);
    }
  }
}

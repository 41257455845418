import {ChangeDetectionStrategy, Component, Input, OnInit, signal} from '@angular/core';
import {CuratorsContactInfo} from '@core/models';

interface InnerData {
  avatar_url: string;
  nickname: string;
  telegram: string;
  whatsapp: string;
  oldFormat: string;
  signal: string;
}

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsComponent implements OnInit {
  @Input() data!: CuratorsContactInfo | undefined;
  @Input() forCustomer = true;

  protected displayData = signal<InnerData>({} as InnerData);
  constructor() {}

  ngOnInit() {
    const mainData = {
      avatar_url: this.data?.avatar_url,
      nickname: this.data?.nickname,
      telegram: this.data?.telegram,
    };

    const stateData = this.forCustomer
      ? {
          whatsapp: this.data?.whatsapp_customers,
          signal: this.data?.signal_customers,
        }
      : {
          signal: this.data?.signal_pressman,
          whatsapp: this.data?.whatsapp_pressmen,
        };

    this.displayData.set({...mainData, ...stateData} as InnerData);
  }

  isSignalLink(signalContact: string) {
    return signalContact.search(/https:\/\/signal\.me\//) !== -1;
  }
}

export const environment = {
  production: false,
  debugRoutes: false,
  env: 'dev',
  apiUrl: 'https://apidev.drukarmy.org.ua/api',
  roles: [
    'admin',
    'curator',
    'customer',
    'pressman',
    'new_pressman',
    'new_customer',
    'banned',
    'developer',
    'assistant',
    'fond_curator',
    'uber_curator',
    'observer',
    'stl_access',
    'hub',
  ],
  defaults: {
    userAvatar: 'assets/images/user-avatar.png',
    productImage: 'assets/images/product-image.jpg',
  },
  novaPoshtaApiKey: '4da55a13fe55991232d086dc3b9c758b',
  maxProductOrder: 10000,
  inviteRedirect: 'https://apidev.drukarmy.org.ua/inv/',
  otherPrinterId: 3,
  clientAppUrl: 'https://app.drukarmy.org.ua/',
  cookieDomain: '.drukarmy.org.ua',
  isAdminPage: true,
  officialLetter: 'https://docs.google.com/document/d/12CIXwmARnt4_QWPUewRim95zH4euQudX',
  howPack: 'https://app.drukarmy.org.ua/school/pakuvannia-nadrukovanykh-vyrobiv',
};

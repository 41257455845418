import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sortByDate',
})
export class SortByDatePipe implements PipeTransform {
  transform(value: any[]): any[] {
    return value.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
  }
}

import {ThemeService} from '@services/theme.service';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UserService} from '@services/user/user.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceComponent {
  homeUrl$ = this.userService.homeUrl$;
  theme$ = this.themeService.currentTheme$;

  constructor(
    private userService: UserService,
    private themeService: ThemeService,
  ) {
    document.getElementById('intro')?.classList.add('hidden');
    this.userService.poolingUser();
  }
}

export enum RouteItem {
  PRODUCTS = 'products',
  ORDERS = 'orders',
  PRINT_TASKS = 'print-tasks',
  MY_TASKS = 'my-tasks',
  PRINTERS = 'printers',
  FILAMENT = 'filament',
  QUESTIONS = 'questions',
  VERIFICATION = 'verification',
  PROFILE = 'profile',
  FEEDBACK = 'feedback',
  PRODUCT_MANAGEMENT = 'product-management',
  REQUESTS = 'requests',
  CUSTOMER_ORDERS = 'customer-orders',
  FILFILLMENT_REPORTS = 'fulfillment-report',
  CUSTOMER_REVIEW = 'order-review',
  SCHOOL = 'school',
  RECRUITING = 'recruiting',
  CHECKOUT = 'checkout',
  CUSTOM_ORDER = 'custom-order',
  DASHBOARD = 'dashboard',
  DOCUMENTS = 'documents',
  PRODUCTION = 'production',
  QUALITY_CHECK = 'quality-check',
  PAGE_NOT_FOUND = 'page-not-found',
  LOGIN = 'login',
  REGISTER = 'register',
  AUTH_RESETPASS_REQ = 'auth/reset-pass/request',
  AUTH_RESETPASS = 'auth/reset-pass',
  AUTH_CONFIRMATION_REQ = 'auth/confirmation/request',
  AUTH_CONFIRMATION = 'auth/confirmation',
  AUTH_UNLOCK_REQ = 'auth/unlock/request',
  AUTH_UNLOCK = 'auth/unlock',
  WEB_EDITOR = 'web-editor',
  STATISTICS = 'statistics',
  SHARE_USAGE_VIDEO = 'share-usage-video',
  DISCUSSIONS = 'discussions',
  REVIEW_CUSTOMERS = 'review-customers',
  HUBS = 'hubs',
  GRATITUDE = 'gratitude',
}

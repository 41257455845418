import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpParams, HttpRequest} from '@angular/common/http';
import {last, Observable, of, catchError, tap, takeUntil, Subject, map} from 'rxjs';
import {environment} from '@env/environment';
import {Router} from '@angular/router';
import {isObject} from 'lodash';

interface UrlOptions {
  root?: boolean;
  throwError?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  readonly apiUrl = environment.apiUrl;
  readonly entityPath: string = '';
  readonly entityName: string = '';
  readonly uiErrorHandler: {error: Function} | undefined = undefined;

  private _abort: Subject<void> = new Subject();
  protected router = inject(Router);

  constructor(protected http: HttpClient) {}

  public get<T>(path: string, data?: any, opName = '', options?: UrlOptions): Observable<T> {
    const requestOptions = {} as any;

    if (data && 'object' === typeof data && Object.keys(data).length) {
      requestOptions.params = new HttpParams({fromObject: data});
    }

    return this.http
      .get(this.buildUrl(path, options?.root), requestOptions)
      .pipe(catchError(this.handleError<T>(opName, undefined))) as Observable<T>;
  }

  public openInNewWindow(path: string, params?: any, options?: UrlOptions): void {
    const requestUrl = this.buildUrl(path, options?.root);

    const httpParams = new HttpParams({fromObject: params || {}});
    const fullUrl = `${requestUrl}?${httpParams.toString()}`;

    window.open(fullUrl, '_blank');
  }

  public post<T>(path: string, data: any, opName = '', options?: UrlOptions): Observable<T> {
    return this.http
      .post<T>(this.buildUrl(path, options?.root), data)
      .pipe(catchError(this.handleError<T>(opName, undefined, options?.throwError)));
  }

  public postProgress<T>(path: string, data: any, opName = '', filename: string, progress: Function): Observable<any> {
    const req = new HttpRequest('POST', this.buildUrl(path), data, {
      reportProgress: true,
    });
    return this.http.request(req).pipe(
      takeUntil(this._abort),
      tap((event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            progress({filename, progress: 0});
            break;

          case HttpEventType.UploadProgress:
            // Compute and show the % done:
            const percentDone = event.total ? Math.round((100 * event.loaded) / event.total) || 1 : 1;
            progress({filename, progress: percentDone});
            break;

          case HttpEventType.Response:
            progress({filename, progress: 100});
            break;

          default:
            progress(-1);
        }
      }),
      last(),
      map((event) => {
        return {...event, filename};
      }),
      catchError(this.handleError<T>(opName, undefined)),
    );
  }

  protected abortProgress() {
    this._abort.next();
  }

  public put<T>(path: string, data: any, opName = '', options?: UrlOptions): Observable<T> {
    return this.http
      .put<T>(this.buildUrl(path, options?.root), data)
      .pipe(catchError(this.handleError<T>(opName, undefined)));
  }

  public delete(path: string, options?: UrlOptions) {
    return this.http.delete(this.buildUrl(path, options?.root)).pipe(catchError(this.handleError<any>('', undefined)));
  }

  private buildUrl(urlPart = '', fromRoot = false): string {
    return [this.apiUrl, fromRoot ? '' : this.entityPath, urlPart].filter(Boolean).join('/');
  }

  private handleError<T>(operation = 'operation', result?: T, throwError = false) {
    return (error: any): Observable<T> => {
      const er = isObject(error.error?.error) ? error.error.error : error.error;

      if (throwError) {
        throw new Error(typeof er == 'string' ? er : Object.values(er)[0]?.toString() || 'error');
      }

      if (error.status == 503 || error.status == 504) {
        this.router.navigate(['/maintenance']);
        return of(result as T);
      }

      if (error.status == 401) {
        throw error;
      }
      if (this.uiErrorHandler) {
        this.uiErrorHandler.error(
          `${
            typeof er == 'string'
              ? er
              : Object.keys(er) + ': ' + Object.values(er)[0] || 'Something bad happened; please try again later.'
          }`,
        );
      } else {
        this.log(`${operation} failed: ${error.message}`);
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.info(message);
  }
}
